<template>
  <page-main
    title="Tests"
    nextText="Accolades and activities"
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/EducationEntryAwards/' + key)"
    @save="save()"
  >
    <template v-slot:aside>
      If it's relevant for your field, record test scores as well - for example,
      an SAT, GMAT, GRE, or LSAT score.
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li>
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Test:</span>
                SAT
              </li>
              <li>
                <span class="font-weight-bold">Score:</span>
                1700
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Tests:"
            labelOne="Test"
            labelTwo="Score"
            buttonLabel="Add Test"
            v-model="educationTests"
            tabindex="2"
            dataNameOne="testName"
            dataNameTwo="testScore"
            keyName="testKey"
          ></r-dual-list>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import * as disp from "../utility/display";

// @ is an alias to /src
export default {
  name: "EducationEntryDegree",
  data: () => ({}),
  components: {},
  mounted() {
    this.key = this.$route.params.key;
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(this.pageKey)) {
        this.createEntry({ key: this.pageKey });
      }
      this.key = this.pageKey;
    }
  },
  methods: {
    save() {
      this.educationTests = [
        ...this.educationTests.filter(
          (t) => !disp.IsNullorWhitespace(t.testName)
        ),
      ];
      this.saveEducation();
    },
    ...mapActions("education", ["saveEducation"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist", "baseDegrees"]),
    ...mapFields("education", {
      key: "key",
      educationTests: "educationTests",
    }),
    nextti() {
      let ti = this.educationTests.length * 3 + 2; // tab index for next button
      return ti.toString();
    },
    tips() {
      return [
        "Add relevant standardized tests – these may include ACT, SAT, GMAT, GRE, LSAT, MCAT, or others.",
      ];
    },
  },
};
</script>
